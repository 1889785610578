import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFavouriteSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.35 19.723c.17.176.407.277.65.277.243 0 .48-.1.65-.277l6.679-6.918a5.02 5.02 0 001.5-2.476A5.17 5.17 0 0021 9.023C21 6.253 18.813 4 16.125 4c-.422 0-.853.06-1.28.18A4.915 4.915 0 0012 6.35a4.915 4.915 0 00-2.845-2.17A4.741 4.741 0 007.875 4C5.187 4 3 6.253 3 9.023a5.173 5.173 0 001.168 3.258c.154.185.323.362.503.524l6.679 6.918z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgFavouriteSolid
