// =============================================================================
//
// Google Publisher Tags (GPT) constants
//
// g_ prefix are key values that are mandating by Central
//
// GPT_KEYS is the main object that contains all the keys that is exported
//
// =============================================================================

import { GPT_CONFIG_GAM, GPT_USER_GAM } from '@/features/advertisement/constants/gam'

/** Keys regarding the user (do not export) */
const GPT_USER = {
  /** Encrypted User ID */
  HASHED_USER_ID_LABEL: 'hu',
  /**
   * Is User logged in?
   * @deprecated - Duplicate
   */
  USER_LOGGED_IN_LABEL_KJ: 'reg_user',
  ...GPT_USER_GAM,
}

const GPT_AUTOS = {
  /** Car body type filter */
  AUTOS_BODY: 'body',
  /** Car year range filter */
  AUTOS_YEAR: 'year',
  /** Car model type filter */
  AUTOS_MODEL: 'model',
  CARBODYTYPE_ATTRIBUTE_ID: 'carbodytype',
  CARCONDITION_ATTRIBUTE_ID: 'vehicletype',
  CARMAKE_ATTRIBUTE_ID: 'carmake',
  CARMILEAGE_ATTRIBUTE_ID: 'carmileageinkms',
  CARMODEL_ATTRIBUTE_ID: 'carmodel',
  CARPROOF_ATTRIBUTE_ID: 'carprooflink',
  CARPROOF_LABEL: 'carproof',
  CARYEAR_ATTRIBUTE_ID: 'caryear',
}

const GPT_CENTRAL = {
  CENTRAL_CONDITION_LABEL: 'g_cco',
  CENTRAL_MAKE_LABEL: 'g_cm',
  CENTRAL_MILEAGE_LABEL: 'km',
  CENTRAL_MODEL_LABEL: 'g_cmo',
  CENTRAL_YEAR_LABEL: 'g_cy',
  /** Ad listing ID (VIP) */
  AD_ID_LABEL: 'g_adid',
  /** Installed version of prebid.js */
  PREBID_VERSION_LABEL: 'g_prebid_version',
  HASHED_DEVICE_ID_LABEL: 'g_hd',
}

/** Keys */
const GPT_LOCATION = {
  CITY_LEVEL: 2,
  /**
   * @deprecated - Duplicate
   */
  CITY_TARGETING_LABEL: 'city',
  /** Location id */
  LOCATION_ID_LABEL: 'lc',
  PROVINCE_LEVEL: 1,
  /**
   * Province classified ad is located
   * @deprecated - Duplicate
   */
  PROVINCE_TARGETING_LABEL: 'province',
  ROOT_LEVEL: 0,
  /**
   * Search location subcity (value ex: cityoftoronto)
   * @deprecated - Duplicate
   * */
  SUB_CITY_TARGETING_LABEL: 'sub',
  SUBAREA_LEVEL: 3,
}

const GPT_SEARCH = {
  /** Search keyword entered by the user in the search bar */
  SEARCH_KEYWORD_TARGET: 'kw',
  /** Search keyword from previous search (same as kw if first search) */
  LAST_SEARCH_KEYWORD_LABEL: 'lkw',
  /**
   * L1 category
   * @deprecated - Duplicate
   */
  LEVEL_ONE_CATEGORY_LABEL: 'l1',
  /**
   * L3 category
   * @deprecated - Duplicate
   * */
  LEVEL_THREE_CATEGORY_LABEL: 'l3',
  /**
   * L2 category
   * @deprecated - Duplicate
   * */
  LEVEL_TWO_CATEGORY_LABEL: 'l2',
}

const GPT_FEATURES = {
  FEATURES_LABEL: 'features',
  HIGHLIGHT_FEATURE_LABEL: 'highlight',
  HOMEPAGE_GALLERY_FEATURE_LABEL: 'hpgallery',
  TOPAD_FEATURE_LABEL: 'topad',
  URGENT_FEATURE_LABEL: 'urgent',
  VETERAN_FRIENDLY_FEATURE_LABEL: 'veteran',
}

const GPT_CONFIG = {
  /**
   * User device type (desktop, tablet, mobile)
   * @deprecated - No use for it
   */
  DEVICE_LABEL: 'dv',
  /** Current language */
  LANGUAGE_LABEL: 'lang',
  /**
   * Indicates if the user uses an adblocker
   * @deprecated - Does not work
   * */
  AD_BLOCKER_LABEL: 'ab',
  ...GPT_CONFIG_GAM,
}

const GPT_PREBID = {
  /** To specify GOT config per page type */
  PAGE_TYPE_LABEL_KJ: 'pagetype',
}

export const GPT_KEYS = {
  ...GPT_AUTOS,
  ...GPT_CENTRAL,
  ...GPT_CONFIG,
  ...GPT_FEATURES,
  ...GPT_LOCATION,
  ...GPT_SEARCH,
  ...GPT_USER,
  ...GPT_PREBID,
  AD_TYPE_LABEL: 'type',
  /** Category IDs */
  CATEGORY_ID_LABEL: 'ct',
  CMA_KEY: 'cma',
  CREDIT_APPLICATION_FORM_LABEL: 'financingAvailable',
  DEALER_NAME_LABEL: 'dealername',
  EMPLOYER_NAME_LABEL: 'employer',
  FIBE_CAMPAIGN: 'bellfibe',
  /** For sale by filter option (Housing related value), also used for VIP TAB */
  FOR_SALE_BY_LABEL: 'forsaleby',
  HEADER_BIDDING_LABEL: 'HBL',
  LISTING_TYPE_LABEL: 'lt',
  MERCHANT_NAME_LABEL: 'merchant',
  PAGE_TEST_LABEL: 'pageTest',
  /** Pagetype mandated by central (differente to pageType ) */
  PAGE_TYPE_LABEL: 'pt',
  /** Current price value range (in cents) */
  PRICE_LABEL: 'price',
  PRICE_RANGE_LABEL: 'range',
  PROPMGR_LABEL: 'propmgr',
  /** Page type group for Central/FAST */
  PTG_LABEL: 'ptg',
  /** Used in dev/test environments only */
  TESTING: 'testing',
  OPT_OUT: 'opt_out',
  INTOWOW: 'intowow_optimized',
}

/** Don't export - Access them through GPT_VALUES */
const GPT_FEATURE_VALUES = {
  URGENT_FEATURE_LABEL: 'urgent',
  HOMEPAGE_GALLERY_FEATURE_LABEL: 'hpgallery',
  TOPAD_FEATURE_LABEL: 'topad',
  HIGHLIGHT_FEATURE_LABEL: 'highlight',
  VETERAN_FRIENDLY_FEATURE_LABEL: 'veteran',
}

const GPT_FOR_SALE_BY_VALUES = {
  FOR_SALE_BY_BUSINESS: 'business',
  FOR_SALE_BY_OWNER: 'owner',
}

export const GPT_VALUES = {
  HOSTNAME: 'kijiji.ca',
  WEBAPPNAME: 'nwa',
  USER_LOGGED_IN_VALUE_FALSE: 'no',
  USER_LOGGED_IN_VALUE_TRUE: 'yes',
  USER_LOGGED_OUT_PAGE: 'signout',
  HEADER_BIDDING_VALUE_FALSE: 'no',
  HEADER_BIDDING_VALUE_TRUE: 'yes',
  PREBID_VERSION: '7.34.0',
  TESTING: 'true',
  OPT_OUT_TRUE: 'yes',
  OPT_OUT_FALSE: 'no',
  ...GPT_FEATURE_VALUES,
  ...GPT_FOR_SALE_BY_VALUES,
}

/**
 * These ptg values are related to vcadmin and there is a heap of logic on BOX in Trackable.java for generating them.
 * On legacy SRP/BRP, googletag.pubads().getTargeting('ptg') always returns an array of identical values.
 * So, for the time being, we will use hardcoded values.
 */
export const PAGE_TEST_GROUP_VALUES = []
