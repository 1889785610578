import {
  isAnyBuyAndSellCategory,
  isAnyJobsCategory,
  isAnyPetsCategory,
  isAutoPartsCategory,
} from '@kijiji/category'

type IsUrgentListingProps = {
  /** Category ID of the listing */
  categoryId: number
  /** Flag value returned by listings */
  categorySpecificBadge: boolean
}

/**
 * It is an urgent listing if the flag: categorySpecificBadge is true
 * AND the category is: Buy and Sell, Vehicles Parts, Tires and Accessories, or Pets
 */
export const isUrgentListing = ({
  categoryId,
  categorySpecificBadge,
}: IsUrgentListingProps): boolean => {
  if (
    categorySpecificBadge &&
    (isAutoPartsCategory(categoryId) ||
      isAnyBuyAndSellCategory(categoryId) ||
      isAnyJobsCategory(categoryId) ||
      isAnyPetsCategory(categoryId))
  )
    return true

  return false
}
