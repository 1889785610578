import {
  type GetUserFavouritesListQuery,
  AuthModalVariant,
  useGetUserFavouritesListQuery,
} from '@kijiji/generated/graphql-types'
import FavouriteOutlineIcon from '@kijiji/icons/src/icons/FavouriteOutline'
import FavouriteSolidIcon from '@kijiji/icons/src/icons/FavouriteSolid'
import { type ThemeProps } from '@kijiji/theme'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import qs from 'query-string'
import { type FC, type MouseEvent, useState } from 'react'
import { useTheme } from 'styled-components'

import { ListingFavouriteButton } from '@/components/shared/listing/styled'
import { useSignInModal } from '@/features/auth/hooks/useSignInModal'
import { useFavouriteListingUpdate } from '@/hooks/useFavouriteListingUpdate'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { trackEvent } from '@/lib/ga/utils/tracking'
import { Button } from '@/ui/atoms/button'

type AllowedColorSchemes = 'grey' | 'purple'
export type FavouriteButtonSize = 'large' | 'xLarge'
export type FavouriteButtonColorScheme = Extract<keyof ThemeProps['colors'], AllowedColorSchemes>

export type FavouriteButtonProps = {
  /**
   * The ID of the listing to favourite.
   */
  listingId: string

  /**
   * Simple is just the heart. Full includes label and outline around for the button.
   */
  variant?: 'simple' | 'simple-with-label' | 'full'
  /**
   * The color scheme of the button.
   * Note: This does not work with the 'full' variant.
   */
  colorScheme?: FavouriteButtonColorScheme
  /**
   * The size of the button.
   * Note: This does not work with the 'full' variant.
   */
  size?: FavouriteButtonSize
}

export const FavouriteButton: FC<FavouriteButtonProps> = ({
  listingId,
  colorScheme = 'grey',
  size = 'large',
  variant = 'simple',
}) => {
  const [isFavourite, setIsFavourite] = useState(false)

  const { t } = useTranslation('favourites')
  const { spacing } = useTheme()

  const { data: userData } = useSession()
  const { openSignInModal } = useSignInModal()
  const { updateFavourite, updateLoading } = useFavouriteListingUpdate()
  const { asPath } = useRouter()

  const { refetch } = useGetUserFavouritesListQuery({
    ssr: false,
    skip: !userData?.user.sub,
    fetchPolicy: 'cache-first',
    onCompleted: (data: GetUserFavouritesListQuery) => {
      if (data.favouriteListingsIds && data.favouriteListingsIds.includes(listingId)) {
        setIsFavourite(true)
      }
    },
  })

  const handleToggleFavourite = (event: MouseEvent<HTMLButtonElement>) => {
    /**
     * Prevents a link redirect to be triggered when used inside of a listing card
     * */
    event.preventDefault()
    event.stopPropagation()

    if (updateLoading) {
      return
    }

    if (!userData?.user) {
      const { url, query: queryParams } = qs.parseUrl(asPath)
      queryParams.listingId = listingId
      const callbackUrl = qs.stringifyUrl({ url, query: queryParams })

      openSignInModal({ modalVariant: AuthModalVariant.LoginToFavourite, callbackUrl })

      return
    }

    setIsFavourite(!isFavourite)

    updateFavourite(isFavourite, {
      variables: { listingId: listingId.toString() },
      onError: (error) => {
        // Catches off case a listing is already favourited resulting in an error.
        // This is a good indication to refetch the list of favourite ids
        if (error.message.includes('already favourited')) {
          refetch()
          return
        }
        setIsFavourite(isFavourite)
      },
      onCompleted: () => {
        trackEvent({
          action: (isFavourite && GA_EVENT.WatchlistRemove) || GA_EVENT.WatchlistAdd,
          label: `adId=${listingId}`,
        })
      },
      onQueryUpdated: () => false, // the trick to preventing a refetch of main query after mutation
    })
  }

  const geFavouritedSolidContent = (
    isFavourite: boolean,
    variant: FavouriteButtonProps['variant']
  ) => {
    if (variant === 'full' || variant === 'simple-with-label') {
      return isFavourite ? (
        <>
          <FavouriteSolidIcon aria-hidden size="xsm" />
          {t('favourites.button.text.saved')}
        </>
      ) : (
        <>
          <FavouriteOutlineIcon aria-hidden size="xsm" />
          {t('favourites.button.text.save')}
        </>
      )
    }

    return isFavourite ? <FavouriteSolidIcon aria-hidden /> : <FavouriteOutlineIcon aria-hidden />
  }

  if (variant === 'full') {
    return (
      <Button
        variant="secondary"
        size="small"
        title={`${t('favourites.button.click_to_favourite')}`}
        aria-label={
          isFavourite
            ? `${t('favourites.button.label_remove')}`
            : `${t('favourites.button.label_add')}`
        }
        aria-pressed={isFavourite}
        data-testid="listing-favourite-button"
        onClick={handleToggleFavourite}
        type="button"
        gap={spacing.mini}
        isLoading={updateLoading}
      >
        {geFavouritedSolidContent(isFavourite, variant)}
      </Button>
    )
  }

  return (
    <ListingFavouriteButton
      title={`${t('favourites.button.click_to_favourite')}`}
      aria-label={
        isFavourite
          ? `${t('favourites.button.label_remove')}`
          : `${t('favourites.button.label_add')}`
      }
      aria-pressed={isFavourite}
      data-testid="listing-favourite-button"
      onClick={handleToggleFavourite}
      type="button"
      colorScheme={colorScheme}
      size={size}
      variant={variant}
    >
      {geFavouritedSolidContent(isFavourite, variant)}
    </ListingFavouriteButton>
  )
}
