import { isPositiveInteger } from '@kijiji/number/isPositiveInteger';
import { formatWholeNumber } from '@kijiji/number/formatWholeNumber';
import padEnd from 'lodash.padend';
import KeyCodes from './lib/keycodes';

export const KEYCODE_MIN_NUMERIC = 48;
export const KEYCODE_MAX_NUMERIC = 57;
export const KEYCODE_MIN_NUMERIC_KEYPAD = 96;
export const KEYCODE_MAX_NUMERIC_KEYPAD = 105;

export const CENTS: number = 1;
export const DOLLARS: number = CENTS * 100;

/**
 * Parses a string representing a price into an integer representing the
 * appropriate number of cents.
 *
 * For example "17.5" ($17.50) would become 1750.
 *
 * @param    string    price    The price to parse.
 *
 * @return   integer            The corresponding number of cents.
 */
export const parsePrice = (price: any) => {
  if (typeof price !== 'string') {
    return null;
  }

  const PRICE = /^(\d+)\.?(\d+?)?$/;
  const priceParts = price.trim().match(PRICE);

  if (priceParts == null) {
    return null;
  }

  const dollars = priceParts[1];
  const cents = priceParts[2];

  const stringCents = cents || '';

  const normalizedCents = padEnd(stringCents.substring(0, 2), 2, '0');

  return parseInt(dollars + normalizedCents, 10);
};

/**
 * Converts an integer monetary value (denominated in cents)
 * into structured data representing the price's components.
 * Pads cent values with an extra zero when less than 10.
 *
 * @param    {number}    cents    The price in cents.
 *
 * @returns  {object}             The price's components:
 *                                  - dollars: {string} The whole dollar amount
 *                                  - cents: {string} The cents amount, always two digits
 *
 * @throws   {TypeError}          If the input is not a number
 * @throws   {RangeError}         If the input is negative
 */
export const priceFromCents = (cents: number): { dollars: string; cents: string } => {
  if (typeof cents !== 'number') {
    throw new TypeError('Input must be a number');
  }

  if (cents < 0) {
    throw new RangeError('Input must be a non-negative number');
  }

  const dollars = Math.floor(cents / 100).toString();
  const centsValue = (cents % 100).toString().padStart(2, '0');

  return {
    dollars,
    cents: centsValue,
  };
}

/**
 * Format a number as currency, localized
 *
 * @param   integer    value    The price in cents.
 * @param   object     config   config.isFrench - render price localized in French
 *                              config.suppressCents - hide cents and round up to nearest dollar
 *
 * @return  string
 */
export const formatPrice = (
  value: number,
  config: { isFrench?: boolean; suppressCents?: boolean } = { isFrench: false, suppressCents: false },
) => {
  const { isFrench, suppressCents } = config;

  if (!isPositiveInteger(value)) {
    return null;
  }

  const price = priceFromCents(value);

  const { dollars, cents } = price;
  let dollarsOnly: number = 0;
  if (suppressCents) {
    dollarsOnly = Math.ceil(value / DOLLARS);
  }

  let localizedPrice;

  if (isFrench) {
    localizedPrice = suppressCents
      ? `${formatWholeNumber(dollarsOnly, false)} $`
      : `${formatWholeNumber(Number.parseInt(dollars, 10), false)},${cents} $`;
  } else {
    localizedPrice = suppressCents
      ? `$${formatWholeNumber(dollarsOnly, true)}`
      : `$${formatWholeNumber(Number.parseInt(dollars, 10), true)}.${cents}`;
  }
  return localizedPrice;
};

/**
 * Format a price in shorthand, localized
 * e.g. 120000 to $1.2K, 340000000 to $3.4M
 *
 * @param   integer    value    The price in cents.
 * @param   object     config   config.isFrench - render price localized in French
 *                              config.significantDigits - number of significant digits to display
 *
 * @return  string
 */
export const formatPriceCompact = (
  value: number,
  config: { isFrench?: boolean, significantDigits?: number } = { isFrench: false, significantDigits: 3 },
) => {
  const { isFrench, significantDigits } = config;
  const locale = isFrench ? 'fr-CA' : 'en-CA';

  const dollars = Math.floor(value / 100)

  let formattedAmount = new Intl.NumberFormat(locale, 
    { notation:'compact', maximumSignificantDigits: significantDigits}).format(dollars);

  return isFrench ? `${formattedAmount} $` : `$${formattedAmount}`;
}

const sanitizedInput = (str: string, french: boolean) => {
  const removedCharsRegex = french ? /[^,.\d]/g : /[^.\d]/g;
  const numericOnly = str.replace(removedCharsRegex, '');
  if (french) {
    return numericOnly.replace(',', '.').replace(' ', ',');
  }

  return numericOnly;
};

/**
 * Reformats the price string entered by a user
 */
export const formatPriceInput = (inputStr: string, isFrench: boolean = false) => {
  const numericOnly = sanitizedInput(inputStr, isFrench);

  const parsed = parsePrice(numericOnly);

  const price =
    parsed === null
      ? {
        dollars: '0',
        cents: '00',
      }
      : priceFromCents(parsed);

  const { dollars, cents } = price;

  return isFrench
    ? `${formatWholeNumber(Number.parseInt(dollars, 10), false)},${cents} $`
    : `$${formatWholeNumber(Number.parseInt(dollars, 10), true)}.${cents}`;
};

/**
 * Validates input of a text field designed for price (numeric) input.
 * Handles price in french allowing a single comma or decimal
 * Allows left and right arrow as well
 */

export const validatePriceInput = (
  event: { which?: any; keyCode: number; target: { value: string }; shiftKey: boolean; metaKey?: any },
  isFrench = false,
) => {
  const key = event.which ? event.which : event.keyCode;
  const inputValue = event.target.value;

  const validInputs = [
    KeyCodes.backspace,
    KeyCodes.comma,
    KeyCodes.end,
    KeyCodes.home,
    KeyCodes.left,
    KeyCodes.numlockon.point,
    KeyCodes.space,
    KeyCodes.tab,
    KeyCodes.point,
    KeyCodes.right,
    KeyCodes.delete,
  ];

  const allowedInputs = new Set(validInputs);
  const keyCodeIsAllowed = (keyCode: number) =>
    (((keyCode >= KEYCODE_MIN_NUMERIC && keyCode <= KEYCODE_MAX_NUMERIC) ||
      (keyCode >= KEYCODE_MIN_NUMERIC_KEYPAD && keyCode <= KEYCODE_MAX_NUMERIC_KEYPAD)) &&
      !event.shiftKey) ||
    allowedInputs.has(keyCode) ||
    event.metaKey;

  const decimalOrComma = (keyCode: any) =>
    keyCode === KeyCodes.point || keyCode === KeyCodes.numlockon.point || keyCode === KeyCodes.comma;

  // Allow tab and shift+tab
  if (key === KeyCodes.tab) {
    return true;
  }

  // Don't allow comma in English
  if (!isFrench && key === KeyCodes.comma) {
    return false;
  }

  if (!keyCodeIsAllowed(key)) {
    return false;
  }

  if (decimalOrComma(key)) {
    // Only allow if the input doesn't already contain the character
    return inputValue.indexOf('.') < 0 && inputValue.indexOf(',') < 0;
  }

  return true;
};

export const numberToCents = (value: number) => value * DOLLARS;
