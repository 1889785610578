// =============================================================================
//
// Google Advertisment Manager (GAM) constants
//
// =============================================================================

export const GPT_CONFIG_GAM = {
  /** Enable RevOps/FAST to distinguish the display ad performance from legacy vs new web app (used for GAM) */
  WEB_VERSION: 'wv',
  /** To differenciate new vs legacy (used for GAM) */
  WEBAPPNAME: 'webappname',
  /** Current page number */
  PAGE_TARGET: 'g_pn',
  /**
   * Host Name (web, android or ios)
   * @deprecated - No use
   */
  HOSTNAME_LABEL: 'hn',
}

export const GPT_USER_GAM = {
  /** Is User logged in? */
  USER_LOGGED_IN_LABEL: 'li',
}
