import { theme } from '@kijiji/theme'
import styled, { type DefaultTheme } from 'styled-components'

import {
  type FavouriteButtonColorScheme,
  type FavouriteButtonProps,
  type FavouriteButtonSize,
} from '@/components/shared/listing/FavouriteButton'
import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

type ListingFavouriteButtonProps = {
  colorScheme: FavouriteButtonColorScheme
  size: FavouriteButtonSize
  variant?: FavouriteButtonProps['variant']
}

const getListingFavouriteButtonColor = (
  colorScheme: FavouriteButtonColorScheme,
  theme: DefaultTheme
) => {
  const colorSchemesConfig: Record<
    FavouriteButtonColorScheme,
    {
      pressed: string
      unpressed: string
    }
  > = {
    purple: {
      pressed: theme.colors.purple.primary,
      unpressed: theme.colors.purple.primary,
    },
    grey: {
      pressed: theme.colors.purple.primary,
      unpressed: theme.colors.grey.light1,
    },
  }

  return `
    color: ${colorSchemesConfig[colorScheme].unpressed};
    
    &[aria-pressed="true"] > svg {
      color: ${colorSchemesConfig[colorScheme].pressed};
    }
  `
}

const iconSizes: Record<FavouriteButtonSize, string> = {
  large: theme.spacing.large,
  xLarge: theme.spacing.xLarge,
}

// eslint-disable-next-line @kijiji/prefer-button-component
export const ListingFavouriteButton = styled.button<ListingFavouriteButtonProps>(
  ({ theme, colorScheme, size, variant }) => `
  ${styleFromTypography(theme.typography.body.small)}
  align-items: center;
  color ${theme.colors.purple.primary};
  display: flex;
  gap: ${theme.spacing.mini};
  transition: transform .9s;
  z-index: ${zIndexRegistry.CARD_ELEMENTS};
  
  & > svg {
    height: ${iconSizes[size]};
    width: ${iconSizes[size]};
  }

  ${
    variant !== 'simple-with-label' &&
    `
    ${getListingFavouriteButtonColor(colorScheme, theme)}
   
    &:hover {
      transform: scale(1.2);
    }
  `
  }
`
)
