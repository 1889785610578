import Script from 'next/script'

export const AdSenseScripts = () => {
  return (
    <>
      <Script
        id="adsense"
        data-testid="adsense-script"
        strategy="afterInteractive"
        src={`https://www.google.com/adsense/search/ads.js`}
      />
      <Script
        id="adsense-setup"
        dangerouslySetInnerHTML={{
          __html: `(function(g,o){g[o]=g[o]||function(){(g[o]['q']=g[o]['q']||[]).push(arguments)},g[o]['t']=1*new Date})(window,'_googCsa');`,
        }}
      />
    </>
  )
}
