import { type UserStatus } from 'next-auth'

import { GPT_KEYS } from '@/features/advertisement/constants/gpt'
import { type GptTargeting } from '@/features/advertisement/types/adConfig'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { type RouteLocale } from '@/hooks/useLocale'
import { getHashedUserIdFromCookie } from '@/utils/cookies/getHashedUserIdFromCookie'

/**
 * Returns an object with the language label key-value pair, using the value of the `routeLocale`
 * variable.
 *
 * @param {string} routeLocale - The router locale string to use for the label.
 * @returns {Object} An object containing the language label key-value pair.
 *
 * @example
 * const languageLabel = createLanguageLabel('en');
 * console.log(languageLabel); // { key: 'lang', value: 'en' }
 */
export function createLanguageLabel(routeLocale: RouteLocale) {
  return {
    key: GPT_KEYS.LANGUAGE_LABEL,
    value: routeLocale,
  }
}

/**
 * Returns an object with the user logged-in label key-value pair, using the value of the
 * `isAuthenticated` variable.
 *
 * @param {boolean} isAuthenticated - A boolean value indicating whether the user is authenticated.
 * @returns {Object} An object containing the user logged-in label key-value pair.
 * @example
 *
 * const userLoggedInLabel = createUserLoggedInLabel('authenticated');
 * console.log(userLoggedInLabel); // { key: 'li', value: 'yes' }
 */
export function createUserLoggedInLabel(authenticationStatus: UserStatus): GptTargeting {
  return {
    key: GPT_KEYS.USER_LOGGED_IN_LABEL,
    value: isUserAuthenticated(authenticationStatus) ? 'yes' : 'no',
  }
}

/**
 * Returns an object with the user logged-in label key-value pair, using the value of the
 * `isAuthenticated` variable.
 *
 * @param {boolean} isAuthenticated - A boolean value indicating whether the user is authenticated.
 * @returns {Object} An object containing the user logged-in label key-value pair.
 *
 * @example
 * const userLoggedInLabelKj = createUserLoggedInLabelKj('loading');
 * console.log(userLoggedInLabelKj); // { key: 'user_logged_in_label_kj', value: 'no' }
 * @deprecated
 */
export function createUserLoggedInLabelKj(authenticationStatus: UserStatus) {
  return {
    key: GPT_KEYS.USER_LOGGED_IN_LABEL_KJ,
    value: isUserAuthenticated(authenticationStatus) ? 'yes' : 'no',
  }
}

/**
 * Returns an object with the hashed user ID label key-value pair, using the value of the
 * `hashedUserId` variable if it is defined.
 *
 * @param {string} hashedUserId - The hashed user ID to use for the label.
 * @returns {Object[]} An array containing an object with the hashed user ID label key-value pair, or
 * an empty array if `hashedUserId` is not defined.
 *
 * @example
 * // Assuming getHashedUserId() returns 'abc123'
 * const hashedUserIdLabel = createHashedUserIdLabel();
 * console.log(hashedUserIdLabel); // { key: 'hu', value: 'abc123' }
 */
export function createHashedUserIdLabel() {
  const hashedUserId = getHashedUserIdFromCookie()

  if (hashedUserId) {
    return {
      key: GPT_KEYS.HASHED_USER_ID_LABEL,
      value: hashedUserId,
    }
  }
}
