/**
 * Manually triggers a refresh of a specific ad slot by its element ID.
 * The function checks if the Google Publisher Tag (GPT) API is ready and
 * iterates through the available ad slots to find the one with the matching
 * element ID. Once found, the function refreshes the ad slot.
 *
 * @function
 * @name triggerManualAdRefresh
 * @param {string} adId - The element ID of the ad slot to be refreshed.
 * @example
 * // Manually refresh the ad slot with element ID 'ad-slot-1'
 * triggerManualAdRefresh('ad-slot-1');
 */
export const triggerManualAdRefresh = (adId: string) => {
  if (typeof window !== 'undefined' && window.googletag && googletag.apiReady) {
    const slots = googletag.pubads().getSlots()

    for (const slot of slots) {
      // Need to find the correct slot to pass into the refresh fn.
      if (slot.getSlotElementId() === adId) {
        googletag.pubads().refresh([slot])
        break
      }
    }
  }
}
