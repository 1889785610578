import { GPT_KEYS, GPT_VALUES } from '@/features/advertisement/constants/gpt'
import { type GptTargeting } from '@/features/advertisement/types/adConfig'

/**
 * Returns an object with the web version target key-value pair, if the environment variable
 * `NEXT_PUBLIC_WEBAPP_VERSION` is defined.
 * Used differentiate between NWA & legacy in GAM
 *
 * @returns {Object} An object containing the web version target key-value pair, or an empty
 * object if the environment variable is not defined.
 * @example
 *
 * process.env.NEXT_PUBLIC_WEBAPP_VERSION = '1.2.3'
 * const result = createWebVersionTarget()
 * console.log(result); // { key: GPT_KEYS.WEB_VERSION, value: '1.2.3' }
 */
export function createWebVersionTarget() {
  if (process.env.NEXT_PUBLIC_WEBAPP_VERSION) {
    return {
      key: GPT_KEYS.WEB_VERSION,
      value: process.env.NEXT_PUBLIC_WEBAPP_VERSION,
    }
  }
}

export function createWebappName() {
  return {
    key: GPT_KEYS.WEBAPPNAME,
    value: GPT_VALUES.WEBAPPNAME,
  }
}

/**
 * Returns an array containing an object with the Prebid version label key-value pair
 *
 * @returns {Object[]} An array containing an object with the Prebid version label key-value pair, or
 * an empty array if `prebidVersion` is not defined.
 * @example
 *
 * const prebidVersionLabel = createPrebidVersionLabel();
 * console.log(prebidVersionLabel); // { key: 'g_prebid_version', value: '1.0.0' }
 */
export function createPrebidVersionLabel() {
  // TODO: get Prebid version from filename
  return {
    key: GPT_KEYS.PREBID_VERSION_LABEL,
    value: GPT_VALUES.PREBID_VERSION,
  }
}

/**
 * Returns an object with the hostname label key-value pair, using the value of the `GPT_VALUES.HOSTNAME`
 * constant.
 *
 * @returns {Object} An object containing the hostname label key-value pair.
 * @example
 *
 * const hostnameLabel = createHostnameLabel();
 * console.log(hostnameLabel); // { key: 'hn', value: 'example.com' }
 * @deprecated
 */
export function createHostnameLabel() {
  return {
    key: GPT_KEYS.HOSTNAME_LABEL,
    value: GPT_VALUES.HOSTNAME,
  }
}

/**
 * Returns an object with the header bidding label key-value pair.
 *
 * @returns {Object} An object containing the header bidding label key-value pair.
 * @example
 *
 * const headerBiddingLabel = createHeaderBiddingLabel();
 * console.log(headerBiddingLabel); // { key: 'HBL', value: 'yes' }
 */
export function createHeaderBiddingLabel() {
  return {
    key: GPT_KEYS.HEADER_BIDDING_LABEL,
    value: GPT_VALUES.HEADER_BIDDING_VALUE_TRUE,
  }
}

/**
 * Returns an object with Test Ad Targeting Config key-value pair.
 *
 * @returns {Object} An object containing est Ad Targeting Config key-value pair.
 * @example
 *
 * const testAdTargetConfig = createTestAdTargeting();
 * console.log(testAdTargetConfig); // { key: 'testing', value: 'true' }
 */
export const createTestTargetConfig = (): GptTargeting | undefined => {
  if (process.env.NEXT_PUBLIC_DISPLAY_ADS_TEST_MODE === 'true') {
    return {
      key: GPT_KEYS.TESTING,
      value: GPT_VALUES.TESTING,
    }
  }
}

/**
 * Returns an object with Opt-out Targeting Config key-value pair.
 * This is mainly use for Quebec law 25 compliance that allows users to opt out of targeted ads.
 * Could be possibly rolled out to further regions in the future.
 *
 * @returns {Object} An object containing Opt-out Targeting Config key-value pair.
 *
 * @example
 * const optOutTargetingConfig = createOptOutTargetingConfig(true);
 * console.log(optOutTargetingConfig); // { key: 'opt_out', value: 'yes' }
 */
export const createOptOutTargetingConfig = (hasUserOptedOutTargeting: boolean): GptTargeting => {
  if (hasUserOptedOutTargeting) {
    return {
      key: GPT_KEYS.OPT_OUT,
      value: GPT_VALUES.OPT_OUT_TRUE,
    }
  } else {
    return {
      key: GPT_KEYS.OPT_OUT,
      value: GPT_VALUES.OPT_OUT_FALSE,
    }
  }
}
