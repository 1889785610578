// Home for all GOT related functions
import {
  AD_NETWORK_CODE,
  AD_PATH_BASE,
  AD_PATH_L0,
  GPT_COMPONENT_ID,
} from '@/features/advertisement/constants/adSlots'
import {
  type AdSizesArray,
  type GptTargeting,
  type GptTargetingProps,
  type GptValue,
  type MediaTypes,
} from '@/features/advertisement/types/adConfig'

/**
 * add the sizes array to the mediaTypes object
 * mediaTypes object is passed to Prebid as part of ad slot config
 * @param sizes array sizes for a given ad unit
 * @returns MediaTypes object, e.g. { banner: { sizes: [[300, 250], [300, 600]] } }
 */
export const createMediaTypes = (sizes: AdSizesArray): MediaTypes => {
  return {
    banner: {
      sizes,
    },
  }
}
/**
 * returns the SRP page type based on totalListings and keywords
 * pageType is used to determine the GOT endpoint
 * @param totalListings
 * @param keywords
 */
export const getSearchPageType = (totalListings: number, keywords: string) => {
  if (totalListings === 0) return 'zsrp'
  if (keywords) return 'srp'

  return 'brp'
}

/**
 * lazyLoad is enabled globally in gptGlobalProperties,
 * but we want to disable it for top leaderboard & skyscraper,
 * which are already in the viewport on first load.
 *
 * @param id id for ad
 * @returns boolean
 */
export const shouldDisableLazyLoad = (id: string) => {
  return (
    id === GPT_COMPONENT_ID.TOP_LEADERBOARD ||
    id === GPT_COMPONENT_ID.SKYSCRAPER ||
    id === GPT_COMPONENT_ID.MWEB_LEADERBOARD
  )
}

export const flattenGptArray = (gpt: GptTargeting[]) => {
  const flattenedArray: { [key: string]: GptValue } = {}

  gpt.forEach((entry) => {
    flattenedArray[entry.key] = entry.value
  })

  return flattenedArray
}

/**
 * @param pathLevel path level to be sanitized
 * @returns a sanitized path level, eg: input: women's -> output: womens
 */
const charsToBeReplacedFromPath = /[!"#$%&'()*+,./:;<=>?@[\]^_`{|}~'"'“”‘’•…—–\s]/g // All special chars and a whitespace excluding dash -
const pathCharsReplacement = '_'
const sanitizePathLevel = (pathLevel: string | string[]) => {
  if (Array.isArray(pathLevel)) {
    if (!pathLevel.some((pathLevel) => charsToBeReplacedFromPath.test(pathLevel))) return pathLevel
    return pathLevel.map((pathLevel) =>
      pathLevel.replace(charsToBeReplacedFromPath, pathCharsReplacement)
    )
  }
  return charsToBeReplacedFromPath.test(pathLevel)
    ? pathLevel.replace(charsToBeReplacedFromPath, pathCharsReplacement)
    : pathLevel
}

/**
 * @param gptTargetingProps targeting values that get passed to GPT
 * @returns ad unit path, eg '/5138/kij.ca.srp-web-en/buy___sell/audio/ipod___mp3_accessories'
 */
export const generateAdUnitPath = (gptTargetingProps: GptTargetingProps) => {
  const { lang, pagetype, l1, l2, l3 } = gptTargetingProps

  const AD_PATH_L1 = l1 ? l1 : AD_PATH_L0 // L1 undefined if in All Categories
  const AD_PATH_L2 = l2 ? `/${sanitizePathLevel(l2)}` : ''
  const AD_PATH_L3 = l3 ? `/${sanitizePathLevel(l3)}` : ''

  return `/${AD_NETWORK_CODE}/${AD_PATH_BASE}${pagetype}-web-${lang}/${AD_PATH_L1}${AD_PATH_L2}${AD_PATH_L3}`
}
