import dynamic from 'next/dynamic'
import { type PropsWithChildren, createContext, useContext } from 'react'

import { isDevelopment } from '@/constants/others'

const ClientRenderInner = (props: PropsWithChildren) => (
  <ClientRenderProvider>{props.children}</ClientRenderProvider>
)

const ClientRenderContext = createContext<ClientRenderContextData>({ isClient: false })

type ClientRenderContextData = {
  isClient: boolean
}

const ClientRenderProvider = ({ children }: PropsWithChildren) => {
  return (
    <ClientRenderContext.Provider value={{ isClient: true }}>
      {children}
    </ClientRenderContext.Provider>
  )
}

/**
 * Ensures that a component or hook is only called within ClientRender
 */
export function useEnsureClient() {
  const { isClient } = useContext(ClientRenderContext)

  if (!isClient && isDevelopment) {
    throw new Error('This hook may only be used on the client, inside the ClientRender component')
  }
}

/**
 * Forces a component to be rendered on the client side only. Use only where
 * absolutely necessary as this will lose the benefits of server side rendering and decrease
 * performance.
 */
export const ClientRender = dynamic(() => Promise.resolve(ClientRenderInner), {
  ssr: false,
})
