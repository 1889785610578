import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFavouriteOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 5.5C6.014 5.5 4.5 7.08 4.5 9.023c0 .303.041.614.123.927a3.537 3.537 0 001.109 1.794L12 18.237l6.269-6.493a.726.726 0 01.04-.04 3.531 3.531 0 001.068-1.755c.082-.31.123-.623.123-.926 0-1.942-1.514-3.523-3.375-3.523-.286 0-.58.042-.875.125-1.141.32-2.05 1.253-2.372 2.435a.905.905 0 01-1.755 0C10.8 6.878 9.892 5.944 8.75 5.625a3.245 3.245 0 00-.876-.125zM12 20c-.243 0-.48-.1-.65-.277l-6.678-6.918a4.994 4.994 0 01-.504-.524A5.173 5.173 0 013 9.023C3 6.253 5.187 4 7.875 4c.423 0 .854.06 1.28.18A4.915 4.915 0 0112 6.35a4.915 4.915 0 012.845-2.17c.427-.12.857-.18 1.28-.18C18.813 4 21 6.253 21 9.023c0 .431-.058.871-.172 1.306a5.02 5.02 0 01-1.499 2.476l-6.678 6.918A.91.91 0 0112 20z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgFavouriteOutline
