/**
 * Formats a whole number with thousands separator(s) every 3 digits, in English or French.
 * Yes, this is brute force. Anything after the decimal is discarded.
 *
 * @param {Number} value The number to format.
 * @param {Boolean} isEnglish True if English formatting should be used, otherwise French.
 * @param {boolean} breakingSpace True to use '\u00a0' (non-breaking space) as separator, false for regular space.
 * @returns {String} The formatted number, e.g., 1000 --> 1,000
 */
export const formatWholeNumber = (
  value: number,
  isEnglish: boolean,
  breakingSpace: boolean = true
) => {
  const REGEX = /\B(?=(\d{3})+(?!\d))/g

  const space = breakingSpace ? '\u00a0' : ' '
  const separator = isEnglish ? ',' : space

  return Math.floor(value).toString().replace(REGEX, separator)
}
