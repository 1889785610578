import Script from 'next/script'

import { useScriptOptOut } from '@/hooks/useScriptOptout'

export const AmazonTamScripts = () => {
  const { shouldRenderScript } = useScriptOptOut()

  if (!shouldRenderScript('amazonTAM')) {
    return null
  }

  return (
    <Script
      id="amazon-tam-setup"
      data-testid="amazon-tam-script"
      dangerouslySetInnerHTML={{
        __html: `!function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];g.parentNode.insertBefore(A,g)}("apstag",window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js");`,
      }}
    />
  )
}
