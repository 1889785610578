import { theme } from '@kijiji/theme'

import { AMAZON_TAM_CONFIG } from '@/features/advertisement/constants/tam'
import {
  type AdSizesMap,
  type GptGlobalProperties,
  type PrebidSizeConfig,
  type SizeMappings,
  type SizeMappingsGPT,
} from '@/features/advertisement/types/adConfig'

export const GPT_COMPONENT_ID: Record<string, string> = {
  TOP_LEADERBOARD: 'gpt-leaderboard-top',
  SKYSCRAPER: 'gpt-skyscraper',
  INLINE: 'gpt-inline-srp',
  INLINE_1: 'gpt-inline-srp_1',
  INLINE_2: 'gpt-inline-srp_2',
  INLINE_3: 'gpt-inline-srp_3',
  INLINE_4: 'gpt-inline-srp_4',
  MINI_LEADERBOARD: 'gpt-minileaderboard-base',
  BASE_LEADERBOARD: 'gpt-leaderboard-base',
  MWEB_LEADERBOARD: 'gpt-sticky',
}

export const SIZE_MAPPING_NAMES: Record<string, string> = {
  [GPT_COMPONENT_ID.TOP_LEADERBOARD]: 'TOP_LEADERBOARD',
  [GPT_COMPONENT_ID.SKYSCRAPER]: 'SKYSCRAPER',
  [GPT_COMPONENT_ID.INLINE]: 'INLINE',
  [GPT_COMPONENT_ID.MINI_LEADERBOARD]: 'MINI_LEADERBOARD',
  [GPT_COMPONENT_ID.BASE_LEADERBOARD]: 'BASE_LEADERBOARD',
  [GPT_COMPONENT_ID.MWEB_LEADERBOARD]: 'MWEB_LEADERBOARD',
}

/**
 * global ad path; can be overriden by specifying in ad slots below
 */
export const AD_PATH_PROD_FR = '/5138/kij.ca.buyandsell-fr'
export const AD_PATH_PROD_EN = '/5138/kij.ca.buyandsell'
export const AD_PATH_TEST = '/5138/test_got'

export const AD_NETWORK_CODE = '5138'
export const AD_PATH_BASE = 'kij.ca.'
export const AD_PATH_L0 = 'search' // L0 is All Categories

const PREBID_MEDIA_QUERIES = {
  MOBILE: '(min-width: 0px)',
  TABLET: '(min-width: 744px)',
  DESKTOP: '(min-width: 1024px)',
  DESKTOP_LARGE: '(min-width: 1440px)',
}

export const LEADERBOARD_SCROLL_AWAY_OFFSET = '0px'
export const LEADERBOARD_SCROLL_STICKY_OFFSET = '-150px'
export const INLINE_AD_BREAKPOINT = 1125

// 30 seconds, as recommended by Google
export const AD_REFRESH_COOLDOWN_EXTENDED = 30000
export const AD_REFRESH_COOLDOWN_LEGACY = 8000

// prebid
// legacy use 3000ms
export const PREBID_CONFIG = {
  BIDDER_TIMEOUT: 2000,
  PRICE_GRANULARITY: 'high',
  BIDDER_SEQUENCE: 'random',
}

/**
 * Used for CSS media queries
 * Ad sizes based on:
 * https://miro.com/app/board/uXjVOhxPqW0=/?moveToWidget=3458764536375267082&cot=14
 * https://www.figma.com/file/KtI3PVDoTvo3RoSvZ7NnHJ/Search-Results-Page?node-id=7336%3A317069
 */
export const AD_SLOTS = {
  LEADERBOARD: {
    WIDTH: '728px',
    HEIGHT: '90px',
  },
  LEADERBOARD_L: {
    WIDTH: '970px',
    HEIGHT: '90px',
  },
  LEADERBOARD_XL: {
    WIDTH: '970px',
    HEIGHT: '250px',
  },
  INLINE_S: {
    WIDTH: '300px',
    HEIGHT: '250px',
  },
  INLINE_M: {
    WIDTH: '728px',
    HEIGHT: '250px',
  },
  MINI_LEADERBOARD_S: {
    WIDTH: '328px',
    HEIGHT: '136px',
  },
  MINI_LEADERBOARD_M: {
    WIDTH: '664px',
    HEIGHT: '208px',
  },
  MINI_LEADERBOARD_L: {
    WIDTH: '956px',
    HEIGHT: '208px',
  },
  BASE_LEADERBOARD_S: {
    WIDTH: '320px',
    HEIGHT: '250px',
  },
  MOBILE_LEADERBOARD: {
    WIDTH: '320px',
    HEIGHT: '50px',
  },
}

// based on values that were coming back from GOT
export const adSizesMap: AdSizesMap = {
  [GPT_COMPONENT_ID.TOP_LEADERBOARD]: [
    [728, 90],
    [970, 90],
    [970, 250],
    [1920, 250],
    [3840, 250],
  ], // not on mobile
  [GPT_COMPONENT_ID.SKYSCRAPER]: [
    [160, 600],
    [300, 250],
    [300, 600],
  ], // only on desktop
  [GPT_COMPONENT_ID.INLINE]: [
    [300, 250],
    [320, 50], // mweb only size
    [728, 90],
    [970, 90],
    [970, 250],
  ],
  [GPT_COMPONENT_ID.MINI_LEADERBOARD]: [
    [328, 136], // mweb only size
    [575, 210],
    [956, 208],
  ],
  [GPT_COMPONENT_ID.BASE_LEADERBOARD]: [
    [300, 250],
    [320, 50],
    [728, 90],
    [970, 90],
    [970, 250],
  ],
  [GPT_COMPONENT_ID.MWEB_LEADERBOARD]: [
    [300, 50],
    [320, 50],
  ],
}

// these sizeMappings are used in different formats by GPT and Prebid
export const SIZE_MAPPINGS: SizeMappings = {
  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]]: {
    TABLET: [adSizesMap[GPT_COMPONENT_ID.TOP_LEADERBOARD][0]], // only 728x90
    DESKTOP: adSizesMap[GPT_COMPONENT_ID.TOP_LEADERBOARD].filter((size) => size[0] !== 3840), // remove 3840x250
    DESKTOP_LARGE: adSizesMap[GPT_COMPONENT_ID.TOP_LEADERBOARD],
  },
  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]]: {
    TABLET: [], // not on tablet
    DESKTOP: adSizesMap[GPT_COMPONENT_ID.SKYSCRAPER],
  },
  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]]: {
    MOBILE: [
      adSizesMap[GPT_COMPONENT_ID.INLINE][0], // 300x250
      adSizesMap[GPT_COMPONENT_ID.INLINE][1], // 320x50
    ],
    TABLET: [
      adSizesMap[GPT_COMPONENT_ID.INLINE][0], // 300x250
      adSizesMap[GPT_COMPONENT_ID.INLINE][2], // 728x90
    ],
    DESKTOP_LARGE: adSizesMap[GPT_COMPONENT_ID.INLINE].filter((_, index) => index !== 1), // remove 320x50
  },
  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MINI_LEADERBOARD]]: {
    MOBILE: [adSizesMap[GPT_COMPONENT_ID.MINI_LEADERBOARD][0]], // 328x136
    TABLET: [
      adSizesMap[GPT_COMPONENT_ID.MINI_LEADERBOARD][1], // 575x210
    ],
    DESKTOP: [
      adSizesMap[GPT_COMPONENT_ID.MINI_LEADERBOARD][1], // 575x210
      adSizesMap[GPT_COMPONENT_ID.MINI_LEADERBOARD][2], // 956x208
    ],
  },
  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]]: {
    MOBILE: [
      adSizesMap[GPT_COMPONENT_ID.BASE_LEADERBOARD][0], // 300x250
      adSizesMap[GPT_COMPONENT_ID.BASE_LEADERBOARD][1], // 320x50
    ],
    TABLET: [
      adSizesMap[GPT_COMPONENT_ID.BASE_LEADERBOARD][2], // 728x90
    ],
    DESKTOP: [
      adSizesMap[GPT_COMPONENT_ID.BASE_LEADERBOARD][2], // 728x90
      adSizesMap[GPT_COMPONENT_ID.BASE_LEADERBOARD][3], // 970x90
      adSizesMap[GPT_COMPONENT_ID.BASE_LEADERBOARD][4], // 970x250
    ],
  },
  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]]: {
    MOBILE: adSizesMap[GPT_COMPONENT_ID.MWEB_LEADERBOARD],
  },
}

const SIZE_MAPPINGS_PREBID: PrebidSizeConfig[] = [
  // top leaderboard
  {
    mediaQuery: PREBID_MEDIA_QUERIES.TABLET,
    sizesSupported: SIZE_MAPPINGS.TOP_LEADERBOARD.TABLET,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
  },
  {
    mediaQuery: PREBID_MEDIA_QUERIES.DESKTOP,
    sizesSupported: SIZE_MAPPINGS.TOP_LEADERBOARD.DESKTOP,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
  },

  // skyscraper
  {
    mediaQuery: PREBID_MEDIA_QUERIES.DESKTOP,
    sizesSupported: SIZE_MAPPINGS.SKYSCRAPER.DESKTOP,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
  },

  // inline
  {
    mediaQuery: PREBID_MEDIA_QUERIES.MOBILE,
    sizesSupported: SIZE_MAPPINGS.INLINE.MOBILE,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
  },
  {
    mediaQuery: PREBID_MEDIA_QUERIES.TABLET,
    sizesSupported: SIZE_MAPPINGS.INLINE.TABLET,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
  },
  {
    mediaQuery: PREBID_MEDIA_QUERIES.DESKTOP_LARGE,
    sizesSupported: SIZE_MAPPINGS.INLINE.DESKTOP_LARGE,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
  },

  // mini leaderboard
  {
    mediaQuery: PREBID_MEDIA_QUERIES.MOBILE,
    sizesSupported: SIZE_MAPPINGS.MINI_LEADERBOARD.MOBILE,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MINI_LEADERBOARD]],
  },
  {
    mediaQuery: PREBID_MEDIA_QUERIES.TABLET,
    sizesSupported: SIZE_MAPPINGS.MINI_LEADERBOARD.TABLET,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MINI_LEADERBOARD]],
  },
  {
    mediaQuery: PREBID_MEDIA_QUERIES.DESKTOP,
    sizesSupported: SIZE_MAPPINGS.MINI_LEADERBOARD.DESKTOP,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MINI_LEADERBOARD]],
  },

  // base leaderboard
  {
    mediaQuery: PREBID_MEDIA_QUERIES.MOBILE,
    sizesSupported: SIZE_MAPPINGS.BASE_LEADERBOARD.MOBILE,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
  },
  {
    mediaQuery: PREBID_MEDIA_QUERIES.TABLET,
    sizesSupported: SIZE_MAPPINGS.BASE_LEADERBOARD.TABLET,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
  },
  {
    mediaQuery: PREBID_MEDIA_QUERIES.DESKTOP,
    sizesSupported: SIZE_MAPPINGS.BASE_LEADERBOARD.DESKTOP,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
  },

  // mweb leaderboard
  {
    mediaQuery: PREBID_MEDIA_QUERIES.MOBILE,
    sizesSupported: SIZE_MAPPINGS.MWEB_LEADERBOARD.MOBILE,
    labels: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
  },
]

const SIZE_MAPPINGS_GPT: SizeMappingsGPT = {
  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]]: [
    {
      viewPortSize: [0, 0],
      sizes: [],
    },
    {
      viewPortSize: [theme.breakpoints.medium, 0],
      sizes: SIZE_MAPPINGS.TOP_LEADERBOARD.TABLET, // only 728x90
    },
    {
      viewPortSize: [theme.breakpoints.large, 0],
      sizes: SIZE_MAPPINGS.TOP_LEADERBOARD.DESKTOP,
    },
    {
      viewPortSize: [theme.breakpoints.xLarge, 0],
      sizes: SIZE_MAPPINGS.TOP_LEADERBOARD.DESKTOP_LARGE,
    },
  ],
  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]]: [
    {
      viewPortSize: [0, 0],
      sizes: [],
    },
    {
      viewPortSize: [theme.breakpoints.medium, 0],
      sizes: SIZE_MAPPINGS.SKYSCRAPER.TABLET,
    },
    {
      viewPortSize: [theme.breakpoints.large, 0], // only on desktop
      sizes: SIZE_MAPPINGS.SKYSCRAPER.DESKTOP,
    },
  ],
  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]]: [
    {
      viewPortSize: [0, 0],
      sizes: SIZE_MAPPINGS.INLINE.MOBILE,
    },
    {
      viewPortSize: [theme.breakpoints.medium, 0],
      sizes: SIZE_MAPPINGS.INLINE.TABLET,
    },
    {
      viewPortSize: [theme.breakpoints.xLarge, 0],
      sizes: SIZE_MAPPINGS.INLINE.DESKTOP_LARGE,
    },
  ],

  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MINI_LEADERBOARD]]: [
    {
      viewPortSize: [0, 0],
      sizes: SIZE_MAPPINGS.MINI_LEADERBOARD.MOBILE,
    },
    {
      viewPortSize: [theme.breakpoints.medium, 0],
      sizes: SIZE_MAPPINGS.MINI_LEADERBOARD.TABLET,
    },
    {
      viewPortSize: [theme.breakpoints.large, 0],
      sizes: SIZE_MAPPINGS.MINI_LEADERBOARD.DESKTOP,
    },
  ],
  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]]: [
    {
      viewPortSize: [0, 0],
      sizes: SIZE_MAPPINGS.BASE_LEADERBOARD.MOBILE,
    },
    {
      viewPortSize: [theme.breakpoints.medium, 0],
      sizes: SIZE_MAPPINGS.BASE_LEADERBOARD.TABLET,
    },
    {
      viewPortSize: [theme.breakpoints.large, 0],
      sizes: SIZE_MAPPINGS.BASE_LEADERBOARD.DESKTOP,
    },
  ],
  [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]]: [
    {
      viewPortSize: [0, 0],
      sizes: SIZE_MAPPINGS.MWEB_LEADERBOARD.MOBILE,
    },
    {
      viewPortSize: [theme.breakpoints.medium, 0],
      sizes: [], // not on tablet
    },
  ],
}

export const PREBID_GLOBAL_PROPERTIES = {
  bidderTimeout: PREBID_CONFIG.BIDDER_TIMEOUT,
  priceGranularity: PREBID_CONFIG.PRICE_GRANULARITY,
  bidderSequence: PREBID_CONFIG.BIDDER_SEQUENCE,
  sizeConfig: SIZE_MAPPINGS_PREBID,
  userSync: {
    iframeEnabled: true,
    filterSettings: {
      iframe: {
        bidders: '*',
        filter: 'include',
      },
    },
  },
}

/**
 * global ad config to be passed to the AdvertisingProvider
 * we are now using hardcoded ad sizes to override the ones coming from GOT
 */
export const GPT_GLOBAL_PROPERTIES: GptGlobalProperties = {
  path: AD_PATH_PROD_EN,
  enableLazyLoad: {
    marginPercent: 150,
    mobileScaling: 2,
  },
  prebid: PREBID_GLOBAL_PROPERTIES,
  aps: AMAZON_TAM_CONFIG,
  useAPS: true,
  sizeMappings: SIZE_MAPPINGS_GPT,
}

export const INLINE_AD_POSITIONS = [10, 18, 26, 34]
