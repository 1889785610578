import Script from 'next/script'

import { useScriptOptOut } from '@/hooks/useScriptOptout'
import { getScriptSrcWithPrefix } from '@/utils/cdn'

export const PrebidScripts = () => {
  const { shouldRenderScript } = useScriptOptOut()

  if (!shouldRenderScript('prebid')) {
    return null
  }
  return (
    <>
      <Script
        data-testid="prebid-script"
        src={getScriptSrcWithPrefix('/prebid/prebid-7.34.0.min.js')}
      ></Script>

      <Script
        id="prebid-setup"
        dangerouslySetInnerHTML={{
          // part of prebid setup
          __html: `
               var pbjs = pbjs || {};
               pbjs.que = pbjs.que || [];
            `,
        }}
      />
    </>
  )
}
