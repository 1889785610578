import { type MutationHookOptions } from '@apollo/client'
import {
  type AddFavouriteListingMutation,
  type AddFavouriteListingMutationVariables,
  type RemoveFavouriteListingMutation,
  type RemoveFavouriteListingMutationVariables,
  useAddFavouriteListingMutation,
  useRemoveFavouriteListingMutation,
} from '@kijiji/generated/graphql-types'

export const isRemoveFavouriteOptions = (
  isCurrentlyFavourited: boolean,
  _baseOptions:
    | MutationHookOptions<AddFavouriteListingMutation, AddFavouriteListingMutationVariables>
    | MutationHookOptions<RemoveFavouriteListingMutation, RemoveFavouriteListingMutationVariables>
): _baseOptions is MutationHookOptions<
  RemoveFavouriteListingMutation,
  RemoveFavouriteListingMutationVariables
> => !!isCurrentlyFavourited

export const useFavouriteListingUpdate = () => {
  const [removeFavouriteListing, { loading: removeFavouriteLoading }] =
    useRemoveFavouriteListingMutation()
  const [addFavouriteListing, { loading: addFavouriteLoading }] = useAddFavouriteListingMutation()

  const updateFavourite = (
    isCurrentlyFavourited: boolean,
    _baseOptions:
      | MutationHookOptions<AddFavouriteListingMutation, AddFavouriteListingMutationVariables>
      | MutationHookOptions<RemoveFavouriteListingMutation, RemoveFavouriteListingMutationVariables>
  ) => {
    if (isRemoveFavouriteOptions(isCurrentlyFavourited, _baseOptions)) {
      removeFavouriteListing({
        ..._baseOptions,
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              favouriteListingsIds(existingFavourites = []) {
                return existingFavourites.filter(
                  (id: string) => id !== data?.removeFavouriteListing
                )
              },
            },
            broadcast: false,
          })
        },
      })
    } else {
      addFavouriteListing({
        ..._baseOptions,
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              favouriteListingsIds(existingFavourites = []) {
                return [...existingFavourites, data?.addFavouriteListing]
              },
            },
            broadcast: false,
          })
        },
      })
    }
  }

  return { updateFavourite, updateLoading: removeFavouriteLoading || addFavouriteLoading }
}
